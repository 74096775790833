@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html {
    @apply font-sans text-purple;
    -webkit-tap-highlight-color: transparent;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .cards-horizontal {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }

  .text-headline {
    @apply text-2xl font-semibold leading-snug md:text-3xl;
  }

  .text-sub-headline {
    @apply text-lg font-semibold leading-snug md:text-xl;
  }

  .text-strong {
    @apply font-semibold leading-normal text-md md:text-lg;
  }

  .text-body {
    @apply text-sm font-normal leading-normal md:text-md;
  }

  .text-caption {
    @apply text-xs font-normal leading-snug md:text-sm;
  }

  .text-time {
    @apply font-mono text-xs font-semibold leading-none tracking-2 md:text-sm;
  }

  .text-label {
    @apply leading-snug text-3xs md:text-2xs;
  }

  .price-slider input[type='range']::-webkit-slider-thumb {
    @apply z-50 w-8 h-8 appearance-none pointer-events-auto;
  }

  @media (hover: none) {
    .touch-pointer-events-none {
      pointer-events: none;
    }
  }

  .slider-scrollbar-visible::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
  }

  .slider-scrollbar-visible::-webkit-scrollbar-thumb {
    background: #878787;
    border-radius: 10px;
  }

  .slider-scrollbar-hidden::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
  }

  .slider-scrollbar-hidden::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .width-drawer {
    width: 15rem;
  }

  .phone-bottom-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
